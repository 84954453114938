import $ from 'jquery';
import bootstrap from 'bootstrap';

window.jQuery = $;
window.$ = $;

require('@fancyapps/fancybox');
require('owl.carousel');

(function () {

    // Setup menu
    const actions = $('button[data-layout="open-menu"], button[data-layout="close-menu"]');
    actions.on('click', function () {
      $('html').toggleClass('overlay');
      $('body').toggleClass('open-menu');
    });

}());